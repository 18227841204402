import {
  type AddMultiJpdRequest,
  type AddUserRoleRequest,
  type BucketLicenseKeysRequest,
  type BucketSignedUrlResponse,
  type ChangeProviderRequest,
  type ChangeRegionRequest,
  type DebtPaymentFlowRequest,
  DeploymentType,
  type DowngradeSubscriptionRequest,
  type EditUserRoleRequest,
  type EffectiveCancelSubscriptionRequest,
  type EnterprisePlusAddServersRequest,
  type GeoIpCountries,
  type JpuDTO,
  type LicenseKeysRequest,
  type LicenseKeysResponse,
  type LogShippingEnrollmentRequest,
  type MfaCancelRequest,
  type MfaOtpRequest,
  type MfaStartEnrollmentResponse,
  type MultipleStatisticsRequest,
  type MultipleStatisticsResponse,
  type PaymentType,
  type PlatformSsoAccessRequest,
  type PurchaseCloudRequest,
  ReadMultipleWhiteListIPsRequest,
  type ReadServerIPMetaResponse,
  type ReadServerStatusResponse,
  type RegionDTO,
  type RegionsListResponse,
  type RemoveUseRoleRequest,
  type RequestCurationTrialRequest,
  type RequestJasTrialRequest,
  type ResendUserRoleInvitationEmail,
  type ServerStatusRequest,
  type SubscriptionActionRequest,
  type SubscriptionDTO,
  type SubscriptionResponse,
  type SubscriptionsResponse,
  type UpdateGeoIPCountriesRequest,
  type UpdateWhiteListIPsRequest,
  type UpgradeSubscriptionRequest,
  UserDTO,
  type UserRoleLightDto,
  type WhiteListIPSResponse,
} from "@jfrog-ba/myjfrog-common";
import { useSubscriptionsStore } from "@shared/stores/subscriptions";
import apiProxy from "@shared/utils/api-proxy";
import {
  type GraphTotalItems,
  type ManageEndpointRequestMjf,
  type Region,
} from "@shared/types/localtypes";
import { cookiesService } from "@shared/services/cookies";
import graphUtils from "@shared/utils/graph-utils";
import { addSubscriptionMeta } from "@shared/services/metas/subscriptionMetas";
import { offeringsService } from "@shared/services/offerings";
import { notificationsCenterService } from "@shared/services/notificationsCenter.ts";
import { useEventLogger } from "@shared/use/useEventLogger.ts";
import { EventEntity, EventType } from "@shared/types/localTypes/events.ts";
import { inAppNotificationsService } from "@shared/services/inAppNotifications.ts";
import { usersService } from "@shared/services/user.ts";
import useInAppNotificationsLogic from "@shared/use/useInAppNotificationsLogic.ts";
import { useAuthStore } from "@shared/stores/auth.ts";
import { useBootstrapStore } from "@shared/stores/bootstrap.ts";
import { UsageExposerStatisticsV2Response } from "@shared/services/usageExposer/usageExposerStatistics.ts";
import { GetAQuoteRequestV2 } from "@shared/types/localTypes/marketo.ts";
import { useUpgradeStore } from "@shared/stores/upgrade.ts";

export type InitialData = {
  subscriptions: SubscriptionDTO[];
  user: UserDTO | null;
};

export const subscriptionsService = {
  fetchSubscriptions: async (): Promise<SubscriptionDTO[]> => {
    const response: SubscriptionsResponse | null =
      await apiProxy.get("subscriptions");

    if (!response) {
      throw new Error("Could not load subscriptions");
    }

    return addSubscriptionMeta(response.subscriptions);
  },
  loadInitialData: async (): Promise<void> => {
    const bootstrapStore = useBootstrapStore();
    if (bootstrapStore.initialDataLoaded) {
      return;
    }

    await Promise.all([
      subscriptionsService.getSubscriptionsAndRelatedDependencies(),
      usersService.getOrFetchUser(),
    ]);

    bootstrapStore.setInitialDataLoaded(true);

    const user = useAuthStore().getUser;
    if (user) {
      useInAppNotificationsLogic().startInAppNotificationListening(
        (user as UserDTO).email,
      );
    }
    return;
  },
  getSubscriptionsAndRelatedDependencies: async (): Promise<
    SubscriptionDTO[]
  > => {
    const subscriptions = await subscriptionsService.getSubscriptions();
    // NOTE: [19/12/2023] [kevinz] -> Pricing page is displaying spinner until the offerings are loaded. So here we can safely remove the await of loadOfferings()
    offeringsService.loadOfferings(subscriptions[0].accountNumber);

    // NOTE: [19/12/2023] [kevinz] -> Notification Center is displaying spinner if the notifications are not loaded so we can safely remove the await of loadSmartNotifications()
    notificationsCenterService.loadNotifications();

    // NOTE: [19/12/2023] [kevinz] -> inApp notifications are displaying in Mobile and reactive so we can safely remove the await of loadNotifications()
    inAppNotificationsService.loadNotifications();

    return subscriptions;
  },
  getSubscriptions: async (): Promise<SubscriptionDTO[]> => {
    const subscriptionsStore = useSubscriptionsStore();
    const cachedSubscriptions = subscriptionsStore.getSubscriptions;

    // subscriptions already loaded in Pinia
    if (cachedSubscriptions) {
      return cachedSubscriptions;
    }
    const fetchedSubscriptions =
      await subscriptionsService.fetchSubscriptions();

    await subscriptionsStore.setSubscriptions(fetchedSubscriptions);
    return fetchedSubscriptions;
  },
  findCachedSubscription: (accountNumber: string): SubscriptionDTO | null => {
    if (!accountNumber) {
      return null;
    }
    const subscriptionStore = useSubscriptionsStore();
    return (
      subscriptionStore.getSubscriptions?.find(
        (s) => s.accountNumber === Number(accountNumber),
      ) || null
    );
  },
  extractProviderName: (provider: JpuDTO["cloudProvider"]): string => {
    switch (provider) {
      case "amazon":
        return "Amazon Web Services";
      case "google":
        return "Google Cloud Platform";
      case "azure":
        return "Microsoft Azure";
      default:
        return "-";
    }
  },
  paymentMethod: (subscription: SubscriptionDTO): string | null => {
    const { meta } = subscription;

    if (meta.isBilled) {
      if (meta.isPrepaidPayment) {
        return "Annual";
      }

      if (meta.isMonthlyPayment) {
        return "Monthly";
      }
    }

    if (meta.isMP) {
      const jpu = subscriptionsService.extractMainSaasServer(subscription);

      return `Via ${subscriptionsService.extractProviderName(
        jpu.cloudProvider,
      )}`;
    }

    return null;
  },

  getMultipleStatistics: async (
    multipleStatisticsRequest: MultipleStatisticsRequest,
  ): Promise<MultipleStatisticsResponse> => {
    const response: MultipleStatisticsResponse | null = await apiProxy.post(
      "subscriptions/multipleStatistics",
      {
        multipleStatisticsRequest,
      },
    );
    if (!response) {
      throw new Error("Could not load statistics");
    }
    return response;
  },
  changeProvider: async (
    changeProviderRequest: ChangeProviderRequest,
  ): Promise<null> => {
    return await apiProxy.post(
      "subscriptions/changeProvider",
      changeProviderRequest,
    );
  },

  getCertificates: async (
    accountNumber: SubscriptionDTO["accountNumber"],
  ): Promise<string> => {
    const response: string | null = await apiProxy.get(
      `subscriptions/certificates/${accountNumber}`,
      {
        responseType: "blob",
      },
    );

    if (!response) {
      throw new Error("Could not load certificates file");
    }
    return response;
  },

  changeRegion: async (
    changeRegionRequest: ChangeRegionRequest,
  ): Promise<null> => {
    return await apiProxy.post(
      `subscriptions/changeRegion`,
      changeRegionRequest,
    );
  },

  getRegionsList: async (): Promise<Region[]> => {
    const response: RegionsListResponse | null = await apiProxy.get(
      "subscriptions/regionsList",
    );
    if (!response) {
      throw new Error("Could not load regions list");
    }
    return transformRegions(response.regions);
  },
  purchaseCloud: async (
    request: PurchaseCloudRequest,
  ): Promise<SubscriptionDTO> => {
    const response: SubscriptionResponse | null = await apiProxy.post(
      "subscriptions/purchaseCloud",
      request,
    );

    if (!response) {
      throw new Error("Could not finalize Cloud Purchase");
    }

    useEventLogger().logEvent({
      eventType: request.inQuickPurchase
        ? EventType.QUICK_PURCHASE
        : EventType.PURCHASE,
      eventEntity: EventEntity.PLAN,
      eventData: request as unknown as Record<string, unknown>,
    });

    return addSubscriptionMeta([response.subscription])[0];
  },
  upgradeCloud: async (
    request: UpgradeSubscriptionRequest,
  ): Promise<SubscriptionDTO> => {
    const response: SubscriptionResponse | null = await apiProxy.post(
      "subscriptions/upgrade",
      request,
    );

    if (!response) {
      throw new Error("Could not finalize Cloud Upgrade");
    }

    useEventLogger().logEvent({
      eventType: request.inQuickPurchase
        ? EventType.QUICK_UPGRADE
        : EventType.UPGRADE,
      eventEntity: EventEntity.PLAN,
      eventData: request as unknown as Record<string, unknown>,
    });

    return addSubscriptionMeta([response.subscription])[0];
  },
  downgradeSubscription: async (
    accountNumber: string,
    reason: string,
    newPaymentType: PaymentType,
  ): Promise<SubscriptionDTO> => {
    const request: { reason: string; newPaymentType: PaymentType } = {
      reason,
      newPaymentType,
    };
    const response: SubscriptionResponse | null = await apiProxy.post(
      `subscriptions/${accountNumber}/downgrade`,
      request,
    );
    if (!response) {
      throw new Error(`Could not downgrade subscription '${accountNumber}'`);
    }
    return addSubscriptionMeta([response.subscription])[0];
  },

  sendDowngradeRequest: async (serverName: string): Promise<null> => {
    return await apiProxy.post(`subscriptions/downgrade/${serverName}`, {
      marketoCookie: cookiesService.marketoCookie(),
    } as DowngradeSubscriptionRequest);
  },

  sendSubscriptionActionRequest: async (
    request: SubscriptionActionRequest,
  ): Promise<void> => {
    await apiProxy.post(`subscriptions/requestAction`, request);
  },

  cancelSubscription: async (
    accountNumber: SubscriptionDTO["accountNumber"],
    serverName: JpuDTO["serverName"],
    isJCR: boolean,
    reason: string,
  ): Promise<SubscriptionDTO> => {
    const request: EffectiveCancelSubscriptionRequest = {
      reason,
      serverName,
      isJCR,
      marketoCookie: cookiesService.marketoCookie() || null,
    };
    const response: SubscriptionResponse | null = await apiProxy.post(
      `subscriptions/${accountNumber}/cancel`,
      request,
    );
    if (!response) {
      throw new Error(`Could not cancel subscription '${accountNumber}'`);
    }
    return addSubscriptionMeta([response.subscription])[0];
  },

  startMfaEnrollment: async (
    accountNumber: SubscriptionDTO["accountNumber"],
  ): Promise<MfaStartEnrollmentResponse> => {
    const response: MfaStartEnrollmentResponse | null = await apiProxy.post(
      `subscriptions/${accountNumber}/mfa/enrollment/start`,
      {},
    );
    if (!response) {
      throw new Error("Could not start enrollment");
    }
    return response;
  },

  completeMfaEnrollment: async (
    accountNumber: SubscriptionDTO["accountNumber"],
    request: MfaOtpRequest,
  ) => {
    return await apiProxy.post(
      `subscriptions/${accountNumber}/mfa/enrollment/complete`,
      request,
    );
  },

  cancelMfaEnrollment: async (request: MfaCancelRequest) => {
    return await apiProxy.post(`subscriptions/mfa/enrollment/cancel`, request);
  },

  extractMainSaasServer: (subscription: SubscriptionDTO): JpuDTO => {
    const { jpus } = subscription;
    const firstActiveMothership = jpus.find(
      (jpu) =>
        jpu.status === "ACTIVE" &&
        (jpu.isMothership ||
          jpu.products.find((p) => p.productName === "jfmc")),
    );
    const firstNotActiveMothership = jpus.find(
      (jpu) =>
        jpu.status !== "ACTIVE" &&
        (jpu.isMothership ||
          jpu.products.find((p) => p.productName === "jfmc")),
    );

    const firstActive = jpus.find((jpu) => jpu.status === "ACTIVE");

    return (
      firstActiveMothership ||
      firstNotActiveMothership ||
      firstActive ||
      jpus[0]
    );
  },
  filterSubscriptionsForMothership: (
    subscription: SubscriptionDTO,
  ): JpuDTO[] => {
    const { jpus } = subscription;

    const jpuMotherships = jpus
      .filter(
        (jpu) =>
          jpu.isMothership ||
          jpu.products.find((p) => p.productName === "jfmc"),
      )
      .sort();
    if (jpuMotherships.length === 0) {
      return jpus;
    }
    return jpuMotherships;
  },
  addServersToTopology: async (
    serversRequest: EnterprisePlusAddServersRequest,
  ) => {
    const response: SubscriptionResponse | null = await apiProxy.post(
      `subscriptions/topology/addServers`,
      serversRequest,
    );
    if (!response) {
      throw new Error(`Could not add servers to topology`);
    }
    return addSubscriptionMeta([response.subscription])[0];
  },

  addJpds: async (
    accountNumber: string,
    serversRequest: AddMultiJpdRequest,
  ) => {
    const response: SubscriptionResponse | null = await apiProxy.put(
      `subscriptions/${accountNumber}/addJpd`,
      serversRequest,
    );
    if (!response) {
      throw new Error(`Could not add servers to topology`);
    }
    return addSubscriptionMeta([response.subscription])[0];
  },
  getServersStatusParallel: async (
    accountNumber: string,
    request: ServerStatusRequest,
  ): Promise<ReadServerStatusResponse> => {
    const readServerStatusResponses = await Promise.all(
      request.technicalServerNames.map(async (techName) =>
        subscriptionsService.getServersStatus(accountNumber, {
          technicalServerNames: [techName],
        }),
      ),
    );

    return readServerStatusResponses.reduce(
      (previousValue, currentValue) => ({ ...previousValue, ...currentValue }),
      {} as ReadServerStatusResponse,
    );
  },
  getServersStatus: async (
    accountNumber: string,
    request: ServerStatusRequest,
  ): Promise<ReadServerStatusResponse> => {
    const response: ReadServerStatusResponse | null = await apiProxy.post(
      `subscriptions/${accountNumber}/getServersStatus`,
      request,
    );
    if (!response) {
      throw new Error("Could not load servers status");
    }
    return response;
  },
  addUserRole: async (
    accountNumber: SubscriptionDTO["accountNumber"],
    request: AddUserRoleRequest,
  ): Promise<UserRoleLightDto> => {
    const response: UserRoleLightDto | null = await apiProxy.post(
      `subscriptions/${accountNumber}/addUserRole`,
      request,
    );
    if (!response) {
      throw new Error("An error occurred...");
    }

    useEventLogger().logEvent({
      eventType: EventType.CREATE,
      eventEntity: EventEntity.USER,
      eventData: request as unknown as Record<string, unknown>,
    });

    return response;
  },
  editUserRole: async (
    accountNumber: SubscriptionDTO["accountNumber"],
    request: EditUserRoleRequest,
  ): Promise<UserRoleLightDto> => {
    const response: UserRoleLightDto | null = await apiProxy.put(
      `subscriptions/${accountNumber}/editUserRole`,
      request,
    );
    if (!response) {
      throw new Error("An error occurred...");
    }

    useEventLogger().logEvent({
      eventType: EventType.UPDATE,
      eventEntity: EventEntity.USER,
      eventData: request as unknown as Record<string, unknown>,
    });

    return response;
  },
  removeUserRole: async (
    accountNumber: SubscriptionDTO["accountNumber"],
    request: RemoveUseRoleRequest,
  ): Promise<void> => {
    const response = await apiProxy.post(
      `subscriptions/${accountNumber}/removeUserRole`,
      request,
    );
    if (!response) {
      throw new Error("Error on remove user role...");
    }

    useEventLogger().logEvent({
      eventType: EventType.DELETE,
      eventEntity: EventEntity.USER,
      eventData: request as unknown as Record<string, unknown>,
    });
  },

  resendUserRoleInvitationEmail: async (
    accountNumber: SubscriptionDTO["accountNumber"],
    request: ResendUserRoleInvitationEmail,
  ) => {
    const response = await apiProxy.post(
      `subscriptions/${accountNumber}/resendInviteUserEmail`,
      request,
    );
    if (!response) {
      throw new Error("Error on resend UserRole invitation...");
    }

    useEventLogger().logEvent({
      eventType: EventType.RESEND_INVITATION,
      eventEntity: EventEntity.USER,
      eventData: request as unknown as Record<string, unknown>,
    });
  },
  getServersParallelMetaData: async (
    accountNumber: string,
    request: ServerStatusRequest,
  ): Promise<ReadServerIPMetaResponse> => {
    const readServerStatusResponses = await Promise.all(
      request.technicalServerNames.map(async (technicalServerName) =>
        subscriptionsService.getServersMetaData(accountNumber, {
          technicalServerNames: [technicalServerName],
        }),
      ),
    );

    return readServerStatusResponses.reduce(
      (previousValue, currentValue) => ({ ...previousValue, ...currentValue }),
      {} as ReadServerIPMetaResponse,
    );
  },
  getServersMetaData: async (
    accountNumber: string,
    request: ReadMultipleWhiteListIPsRequest,
  ): Promise<ReadServerIPMetaResponse> => {
    const response: ReadServerIPMetaResponse | null = await apiProxy.post(
      `subscriptions/${accountNumber}/getServersMetaData`,
      request,
    );
    if (!response) {
      throw new Error("Could not load server Ip Meta data");
    }
    for (const responseKey in response) {
      response[responseKey].geoIPMeta["geoIpRestrictionCountriesList"] =
        response[responseKey].geoIPMeta.geoIpRestrictionCountriesList || [];
      response[responseKey].geoIPMeta["geoIpRestrictionType"] =
        response[responseKey].geoIPMeta.geoIpRestrictionType || "deny";
    }
    return response;
  },

  updateWhiteListIPs: async (
    accountNumber: string,
    serverName: JpuDTO["serverName"],
    request: UpdateWhiteListIPsRequest,
  ): Promise<WhiteListIPSResponse> => {
    const whiteListIPSResponse: WhiteListIPSResponse | null =
      await apiProxy.put(
        `subscriptions/${accountNumber}/servers/${serverName}/whiteListIPs`,
        request,
      );
    if (!whiteListIPSResponse) {
      throw new Error("Could not update Whitelist IPs");
    }
    return whiteListIPSResponse;
  },

  manageEndpoint: async (
    accountNumber: string,
    request: ManageEndpointRequestMjf,
  ) => {
    const manageEndpointResponse: boolean | null = await apiProxy.post(
      `subscriptions/${accountNumber}/managePrivateEndpoint`,
      request,
    );
    if (!manageEndpointResponse) {
      throw new Error("Could not update Endpoint");
    }
    return manageEndpointResponse;
  },

  updateGeoIPCountries: async (
    accountNumber: string,
    serverName: JpuDTO["serverName"],
    request: UpdateGeoIPCountriesRequest,
  ): Promise<GeoIpCountries> => {
    const geoIpCountriesResponse: GeoIpCountries | null = await apiProxy.put(
      `subscriptions/${accountNumber}/servers/${serverName}/geoIpCountries`,
      request,
    );
    if (!geoIpCountriesResponse) {
      throw new Error("Could not update geoIp Countries");
    }
    return geoIpCountriesResponse;
  },

  logShippingEnrollment: async (request: LogShippingEnrollmentRequest) => {
    await apiProxy.post(`subscriptions/logShippingEnrollment`, request);

    useEventLogger().logEvent({
      eventType: request.enrollForLogShipping
        ? EventType.ENABLE
        : EventType.DISABLE,
      eventEntity: EventEntity.LOG_SHIPPING,
      eventData: request as unknown as Record<string, unknown>,
    });
  },

  changePlatformAccessForSubscription: async (
    accountNumber: SubscriptionDTO["accountNumber"],
    request: PlatformSsoAccessRequest,
  ): Promise<null> => {
    return await apiProxy.put(
      `subscriptions/${accountNumber}/platformSsoAccess`,
      request,
    );
  },

  createTotalValueForGraphs: (
    serverStatistics: UsageExposerStatisticsV2Response["serverStatistics"],
  ): GraphTotalItems => {
    return {
      dtBytesArtifactory: serverStatistics.reduce(
        graphUtils.sumCallback(["totalArtifactoryTrafficBytes"]),
        0,
      ),
      dtBytesXray: serverStatistics.reduce(
        graphUtils.sumCallback(["totalXrayTrafficBytes"]),
        0,
      ),
      pipeLineMinutes: serverStatistics.reduce(
        graphUtils.sumCallback(["totalBuildMillis"]),
        0,
      ),
      storageBytes: serverStatistics.reduce(
        graphUtils.sumCallback(["totalStorage"]),
        0,
      ),
    };
  },

  debtPaymentFlow: async (
    accountNumber: string,
    request: DebtPaymentFlowRequest,
  ): Promise<null> => {
    return await apiProxy.post(
      `subscriptions/${accountNumber}/debtPaymentFlow`,
      request,
    );
  },

  generateSignedUrl: async (
    accountNumber: SubscriptionDTO["accountNumber"],
    bucketNumber: string,
  ): Promise<BucketSignedUrlResponse> => {
    const response: BucketSignedUrlResponse | null = await apiProxy.get(
      `subscriptions/${accountNumber}/buckets/${bucketNumber}/generateSignedUrl`,
    );
    if (!response) {
      throw new Error("Could not generate signed URL");
    }
    return response;
  },

  fetchByEntitlements: async (
    accountNumber: SubscriptionDTO["accountNumber"],
    request: LicenseKeysRequest,
  ): Promise<LicenseKeysResponse> => {
    const response: LicenseKeysResponse | null = await apiProxy.post(
      `subscriptions/${accountNumber}/licensing/fetchByEntitlements`,
      request,
    );
    if (!response) {
      throw new Error("Error fetching license keys");
    }
    return response;
  },

  fetchByBuckets: async (
    accountNumber: SubscriptionDTO["accountNumber"],
    request: BucketLicenseKeysRequest,
  ): Promise<LicenseKeysResponse> => {
    const response: LicenseKeysResponse | null = await apiProxy.post(
      `subscriptions/${accountNumber}/licensing/fetchByBuckets`,
      request,
    );
    if (!response) {
      throw new Error("Error fetching bucket license keys");
    }
    return response;
  },

  requestAJasTrial: async (request: RequestJasTrialRequest): Promise<void> => {
    await apiProxy.post("subscriptions/request-jas-trial", request);

    useEventLogger().logEvent({
      eventType: EventType.REQUEST_TRIAL_JAS,
      eventEntity: EventEntity.PLAN,
      eventData: request as unknown as Record<string, unknown>,
    });
  },
  requestCurationTrial: async (
    request: RequestCurationTrialRequest,
  ): Promise<void> => {
    await apiProxy.post("subscriptions/request-curation-trial", request);

    useEventLogger().logEvent({
      eventType: EventType.REQUEST_TRIAL_CURATION,
      eventEntity: EventEntity.PLAN,
      eventData: request as unknown as Record<string, unknown>,
    });
  },

  isSelfHosted: (subscription: SubscriptionDTO) => {
    const registrationGroups = subscription.registrationGroups;
    if (!registrationGroups.length) {
      return subscription.paymentType === "BOM_BASED";
    }
    return registrationGroups[0].deploymentType === DeploymentType.SELF_HOSTED;
  },
  isSaas: (subscription: SubscriptionDTO) => {
    return !subscriptionsService.isSelfHosted(subscription);
  },
  sendGetAQuote: async (request: GetAQuoteRequestV2) => {
    await apiProxy.post(`subscriptions/getaquote/v2`, request);

    useEventLogger().logEvent({
      eventType: useUpgradeStore().isQuickUpgrade
        ? EventType.QUICK_GET_QUOTE
        : EventType.GET_QUOTE,
      eventEntity: EventEntity.PLAN,
      eventData: request as unknown as Record<string, unknown>,
    });
  },
};

const transformRegions = (regions: RegionDTO[]): Region[] => {
  return regions.map((region) => {
    return {
      ...region,
      jpuProviderCode: extractJPUProviderCodeFromRegion(region.providerCode),
    };
  });
};

const extractJPUProviderCodeFromRegion = (
  regionProviderCode: RegionDTO["providerCode"],
): JpuDTO["cloudProvider"] => {
  switch (regionProviderCode) {
    case "AWS":
      return "amazon";
    case "AZURE":
      return "azure";
    case "GCP":
      return "google";
  }
};
