import {
  type DomainUnderCertificate,
  type Endpoint,
  type JpdSSOItem,
  type JpuDTO,
  type ManageEndpointRequest,
  type MyJFrogTheme,
  type PolicyDetailsGeoModel,
  type RegionDTO,
  type RegionStatus,
  type SubscriptionComponent,
  type SupportContactStatus,
} from "@jfrog-ba/myjfrog-common";
import { type Option } from "element-plus/es/components/select-v2/src/select.types";
import { ComputedRef } from "vue";
import { Period } from "@shared/services/usageExposer/common-types.ts";
import { MessageSenderType } from "@shared/types/enums.ts";

export type Nullable<T> = T | null;
export interface SessionData {
  sessionId: string;
  userName: string;
  theme: MyJFrogTheme;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface Region extends RegionDTO {
  jpuProviderCode: JpuDTO["cloudProvider"];
}

export interface Region extends RegionDTO {
  jpuProviderCode: JpuDTO["cloudProvider"];
}

export interface UserInfo {
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  company?: string;
  role?: string;
}

export interface changePassword {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface OptionWithIcon extends Option {
  icon?: string;
}

export type SelectOptions = {
  label: string;
  value: string;
  icon?: string;
};
export type CheckboxOptions = {
  label: string;
  icon?: string;
  disabled?: boolean;
};

export interface TopologyUpdatedCard {
  configCardState: {
    jpdName: string;
    jpdRegion: string;
    jpdPassword: string;
    isEnabled: boolean;
  };
  index: number;
  isDirty: boolean;
  isValid: boolean;
}

export type EndpointLabel =
  | "In Progress"
  | "Failed"
  | "Connected"
  | "Blocked"
  | "Not Active"
  | "Create"
  | "Loading"
  | "Removing Endpoint"
  | "Removed";

export interface EndpointStatus {
  label: EndpointLabel;
  statuses: Endpoint["status"][];
}

export interface PrivateEndpointModalMenuStatus {
  [key: string]: EndpointStatus;
}

export interface ManageEndpointRequestMjf
  extends Omit<ManageEndpointRequest, "cloudProviderCode"> {
  cloudProviderCode: JpuDTO["cloudProvider"];
}

export interface DeleteEndpointItem {
  serversToRemove: string[];
  cloudProviderCode: JpuDTO["cloudProvider"];
  endpointId: string;
  region: string;
}

export interface EditEndpointItem {
  oldEndpointId: string;
  jpdNames: JpuDTO["serverName"][];
}

export interface GraphTotalItems {
  dtBytesArtifactory: number;
  dtBytesXray: number;
  pipeLineMinutes: number;
  storageBytes: number;
}

export interface GraphDatePeriod {
  period: Period;
  start: number;
  end: number;
}

export type ScreenSize = "desktop" | "laptop" | "tablet" | "smartphone" | "tv";

export interface IpItem {
  ipAddress: string;
  key: number;
  lineNumber: number;
}

export interface JpdWithSsoAccessStatus extends JpuDTO, JpdSSOItem {}

export interface IncidentStatusInfo {
  text: string;
  icon: RegionStatus["currentStatus"];
}
export interface DateInfo {
  hasIncident: boolean;
  formattedDate: string;
  incidentType: IncidentStatusInfo;
  incidentTimeHours: number;
  incidentTimeMinutes: number;
  incidentDescription: string;
  affectedComps: SubscriptionComponent[];
}

export interface CertificatePageForm {
  certificateName: string;
  certificateBody: string;
  certificatePrivateKey: string;
  certificateChain: string;
  certificateId?: string;
}

export interface CertificatePageFormAndMeta extends CertificatePageForm {
  valid: boolean;
  isCertDetailsPageDirty: boolean;
}

export interface DefinedDomain extends Omit<DomainUnderCertificate, "type"> {
  key: number;
  type:
    | "docker_sub_domain"
    | "platform_base_url"
    | "docker_repository_name_override";
  isWildCard: boolean;
  isNotCoveredInNewCertificate: boolean;
}

export interface ManageDomainsPageStateAndMeta {
  domainUnderCertificateSubmitRequest: DomainUnderCertificate[];
  removedExistingDomains: string[];
  valid: boolean;
  dirty: boolean;
}

export interface CertificateSubmissionEmitEvent {
  certificateName: string;
  certificateExpiry: number;
  certificateId?: string;
}

export interface SupportContactDetails {
  firstName: string;
  lastName: string;
  email: string;
}

export interface ManageSupportContactsQuotaRef {
  isUnableToDefineMoreContacts: ComputedRef<boolean>;
  isLoadingQuota: boolean;
}

export type SupportContactRequestUi = {
  firstName: string;
  lastName: string;
  email: string;
  role: "support";
};

export interface SupportContactResponseUi {
  firstName: string;
  lastName: string;
  email: string;
  role: "support";
  createdDate: string;
  status?: SupportContactStatus;
}

export interface LogStreamerForm {
  secret: string;
  provider: string;
  endpoint: string;
  tags?: string;
}

export enum AppEngine {
  WEB = "WEB",
  MOBILE = "MOBILE",
}

export type DnsRoutingSingleRoutingUrlState = {
  isReadyForSending: boolean;
  singleUrlValue: string;
};

export type DnsRoutingJpdsTopologySetupState = {
  isReadyForSending: boolean;
  primaryJpdTechServerName: JpuDTO["technicalServerName"];
  secondaryJpdTechServerName: JpuDTO["technicalServerName"];
};

export type GeoLocationSetupItem = PolicyDetailsGeoModel & { key: number };

export type DnsRoutingGeoLocationSetupState = {
  isReadyForSending: boolean;
  geoLocationSetup: GeoLocationSetupItem[];
};

export type DnsRoutingPolicy = "manual-failover" | "geolocation";

export type DnsRoutingJpdSelectorOption = {
  serverName: JpuDTO["serverName"];
  technicalServerName: JpuDTO["technicalServerName"];
  region: JpuDTO["region"];
  provider: JpuDTO["cloudProvider"];
  privateEndpoint: "Enabled" | "Disabled";
  type: "Platform" | "Distribution Edge";
  isJpdOptionDisabled: boolean;
};

export type TableStatusOption =
  | "in_progress"
  | "error"
  | "disabled"
  | "active"
  | "passive"
  | "ok"
  | "failed";

export type JpdsSelectorRef = {
  isAllJpdOptionsDisabled: ComputedRef<boolean>;
};

export interface MessageType {
  text: string;
  type: MessageSenderType;
  traceId: string;
}
