import { defineStore } from "pinia";
import { PaymentType } from "@jfrog-ba/myjfrog-common";
import { GetAQuoteAddons } from "@shared/types/localTypes/marketo.ts";

export interface UpgradeStoreStates {
  isQuickUpgradeState: boolean;
  upgradeViewIsVisibleState: boolean;
  buyModalIsOpenState: boolean;
  upgradeModalIsOpenState: boolean;
  getAQuoteModalIsOpenState: boolean;
  buyFlowIsProcessingState: boolean;
  upgradeFlowIsProcessingState: boolean;
  selectedPaymentTypeState: PaymentType | undefined;
  currentUpgradeSelectedState: { [key: string]: GetAQuoteAddons } | null;
}

export const useUpgradeStore = defineStore({
  id: "upgrade",
  state: (): UpgradeStoreStates => ({
    isQuickUpgradeState: false,
    upgradeViewIsVisibleState: false,
    buyModalIsOpenState: false,
    upgradeModalIsOpenState: false,
    getAQuoteModalIsOpenState: false,
    buyFlowIsProcessingState: false,
    upgradeFlowIsProcessingState: false,
    selectedPaymentTypeState: undefined,
    currentUpgradeSelectedState: null,
  }),
  getters: {
    isQuickUpgrade: (state) => state.isQuickUpgradeState,
    upgradeViewIsVisible: (state) => state.upgradeViewIsVisibleState,
    buyModalIsOpen: (state) => state.buyModalIsOpenState,
    upgradeModalIsOpen: (state) => state.upgradeModalIsOpenState,
    getAQuoteModalIsOpen: (state) => state.getAQuoteModalIsOpenState,
    buyFlowIsProcessing: (state) => state.buyFlowIsProcessingState,
    upgradeFlowIsProcessing: (state) => state.upgradeFlowIsProcessingState,
    selectedPaymentType: (state) => state.selectedPaymentTypeState,
    upgradeSelected: (state) => state.currentUpgradeSelectedState,
  },
  actions: {
    setUpgradeSelected(
      paymentType: PaymentType,
      getAQuoteAddons: GetAQuoteAddons,
    ) {
      this.currentUpgradeSelectedState = {
        ...this.currentUpgradeSelectedState,
        [paymentType]: getAQuoteAddons,
      };
    },
    setSelectedPaymentType(paymentType: PaymentType) {
      this.selectedPaymentTypeState = paymentType;
    },
    dismissUpgradeView() {
      if (this.buyFlowIsProcessing || this.upgradeFlowIsProcessing) {
        return;
      }
      this.upgradeViewIsVisibleState = false;
      this.buyModalIsOpenState = false;
      this.upgradeModalIsOpenState = false;
      this.getAQuoteModalIsOpenState = false;
      document.documentElement.classList.remove("upgrade-modal-opened");
    },
    displayUpgradeView() {
      this.isQuickUpgradeState = false;
      this.upgradeViewIsVisibleState = true;
      document.documentElement.classList.add("upgrade-modal-opened");
    },
    setBuyModalIsOpen(isOpen: UpgradeStoreStates["buyModalIsOpenState"]) {
      this.buyModalIsOpenState = isOpen;
    },
    setUpgradeModalIsOpen(
      isOpen: UpgradeStoreStates["upgradeModalIsOpenState"],
    ) {
      this.upgradeModalIsOpenState = isOpen;
    },
    setGetAQuoteModalIsOpen(
      isOpen: UpgradeStoreStates["getAQuoteModalIsOpenState"],
    ) {
      this.getAQuoteModalIsOpenState = isOpen;
    },
    setBuyFlowIsProcessing(
      isProcessing: UpgradeStoreStates["buyFlowIsProcessingState"],
    ) {
      this.buyFlowIsProcessingState = isProcessing;
    },
    setUpgradeFlowIsProcessing(
      isProcessing: UpgradeStoreStates["upgradeFlowIsProcessingState"],
    ) {
      this.upgradeFlowIsProcessingState = isProcessing;
    },
    setIsQuickUpgrade(
      isQuickUpgrade: UpgradeStoreStates["isQuickUpgradeState"],
    ) {
      this.isQuickUpgradeState = isQuickUpgrade;
    },
  },
});
