export enum MessageSenderType {
  User = "user",
  Bot = "bot",
  Error = "error",
}

export enum FeedbackMessageType {
  NOT_FACTUALLY_CORRECT = "not-factually-correct",
  PARTIAL_ANSWER = "partial-answer",
  OTHER = "other",
  THUMB_UP = "thumb-up",
}

export enum MenuItemIcons {
  DASHBOARD = "dashboardNG",
  USAGE = "overviewNG",
  BILLING = "billingNG",
  TOPOLOGY = "topologyNG",
  SECURITY = "securityNG",
  USERS = "usersNG",
  SETTINGS = "settings-sidemenuNG",
  JPD = "deployments",
  LICENSES = "license",
  SANDBOX = "pluginsNG",
  CONTACT = "user_cog",
  NOTIFICATION = "bell_config",
  TICKETS = "support-tickets",
}
