import { Heap } from "@heap/heap-capacitor-bridge";
export type HeapMethods = typeof Heap;

export enum AppcuesEvents {
  NG_SWITCH = "NG_SWITCH",
}

export interface AppcuesMethods {
  identify: (userId: string, options: Record<string, unknown>) => void;
  track: (eventName: AppcuesEvents, payload?: Record<string, unknown>) => void;
}

export interface MyJFrogWindow extends Window {
  google: {
    accounts: {
      id: {
        initialize: (args: unknown) => void;
        renderButton: (args: unknown, arg2: Record<string, unknown>) => void;
      };
    };
  };
  NONCE: number;
  JPC: unknown;
  VERSION: string;
  MOBILE_VERSION: number;
  API_PROXY_URL: string;
  DISPLAY_AI_CHAT: string;
  LANDING_APP_URL: string;
  GOOGLE_CLIENT_ID: string;
  MARKETO_MUNCHKIN_ID: string;
  HEAP_ID: string;
  KNOWLEDGE_CENTER_URL: string;
  APPCUES_ACCOUNT_ID: string;
  APPCUES_APPLICATION_ID: string;
  wzTag: (x: string, item: unknown) => void;
  heap: HeapMethods;
  Appcues: AppcuesMethods;
  PLATFORM_DOMAIN: string;
  FIREBASE_API_KEY: string;
  FIREBASE_AUTH_DOMAIN: string;
  FIREBASE_PROJECT_ID: string;
  FIREBASE_STORAGE_BUCKET: string;
  FIREBASE_MESSAGING_SENDER_ID: string;
  FIREBASE_APP_ID: string;
  OPERATION_MODE: string;
}
