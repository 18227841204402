import { defineStore } from "pinia";
import { RouteNames } from "@shared/types/localTypes/router";
import { type GenVersion } from "@shared/stores/genVersion.ts";
import { MenuItemIcons } from "@shared/types/enums.ts";

interface NavigationStoreStates {
  activeIndex: null | string;
  menuItems: MenuItem[];
  isMenuOpened: boolean;
}

export enum MenuItemNames {
  Dashboard = "Dashboard",
  Usage = "Usage",
  Billing = "Billing",
  Topology = "Topology",
  Security = "Security",
  Users = "Manage Users",
  Settings = "Settings",
  JPD = "JPD",
  Licenses = "Licenses",
  Sandbox = "Sandbox",
  SupportContacts = "Support Contacts",
  NotificationCenter = "Notification Center",
  SupportTickets = "Support Tickets",
}

export enum MenuHeaderNames {
  Dashboard = "Dashboard",
  Usage = "Usage",
  Billing = "Billing",
  Topology = "Topology",
  Security = "Security",
  Users = "Manage Users",
  Subscription = "Subscription",
  Settings = "Settings",
  JPD = "JPDs",
  Licenses = "Licenses",
  Upgrade = "Upgrade",
  AllSubscriptions = "All Subscriptions",
  Sandbox = "Sandbox",
  SupportContacts = "Support Contacts",
  SupportTickets = "Support Tickets",
}

export interface MenuItem {
  name: MenuItemNames;
  routeName: RouteNames;
  icon: (version: GenVersion) => string;
  menuIndex: string;
  disabled: boolean;
  hidden: boolean;
}

export const useNavStore = defineStore({
  id: "navigation",
  state: (): NavigationStoreStates => ({
    activeIndex: "0",
    menuItems: [
      {
        name: MenuItemNames.Dashboard,
        routeName: RouteNames.Dashboard,
        icon: (version) =>
          version === "new-gen" ? MenuItemIcons.DASHBOARD : "dashboard",
        menuIndex: "0",
        disabled: true,
        hidden: true,
      },
      {
        name: MenuItemNames.Usage,
        routeName: RouteNames.Usage,
        icon: (version) =>
          version === "new-gen" ? MenuItemIcons.USAGE : "usage",
        menuIndex: "1",
        disabled: true,
        hidden: true,
      },
      {
        name: MenuItemNames.Billing,
        routeName: RouteNames.Billing,
        icon: (version) =>
          version === "new-gen" ? MenuItemIcons.BILLING : "billing",
        menuIndex: "2",
        disabled: true,
        hidden: true,
      },
      {
        name: MenuItemNames.Topology,
        routeName: RouteNames.Topology,
        icon: (version) =>
          version === "new-gen" ? MenuItemIcons.TOPOLOGY : "deployments",
        menuIndex: "3",
        disabled: true,
        hidden: true,
      },
      {
        name: MenuItemNames.Security,
        routeName: RouteNames.Security,
        icon: (version) =>
          version === "new-gen" ? MenuItemIcons.SECURITY : "security",
        menuIndex: "4",
        disabled: true,
        hidden: true,
      },
      {
        name: MenuItemNames.Users,
        routeName: RouteNames.Users,
        icon: (version) =>
          version === "new-gen" ? MenuItemIcons.USERS : "users",
        menuIndex: "5",
        disabled: true,
        hidden: true,
      },
      {
        name: MenuItemNames.SupportTickets,
        routeName: RouteNames.SupportTickets,
        icon: () => MenuItemIcons.TICKETS,
        menuIndex: "6",
        disabled: true,
        hidden: true,
      },
      {
        name: MenuItemNames.NotificationCenter,
        routeName: RouteNames.NotificationCenter,
        icon: () => MenuItemIcons.NOTIFICATION,
        menuIndex: "7",
        disabled: true,
        hidden: true,
      },
      {
        name: MenuItemNames.SupportContacts,
        routeName: RouteNames.SupportContacts,
        icon: () => MenuItemIcons.CONTACT,
        menuIndex: "8",
        disabled: true,
        hidden: true,
      },
      {
        name: MenuItemNames.Settings,
        routeName: RouteNames.Settings,
        icon: (version) =>
          version === "new-gen" ? MenuItemIcons.SETTINGS : "settings",
        menuIndex: "9",
        disabled: true,
        hidden: true,
      },
      {
        name: MenuItemNames.JPD,
        routeName: RouteNames.JPDs,
        icon: () => MenuItemIcons.JPD,
        menuIndex: "10",
        disabled: true,
        hidden: true,
      },
      {
        name: MenuItemNames.Licenses,
        routeName: RouteNames.Licenses,
        icon: () => MenuItemIcons.LICENSES,
        menuIndex: "11",
        disabled: true,
        hidden: true,
      },
      {
        name: MenuItemNames.Sandbox,
        routeName: RouteNames.Sandbox,
        icon: (version) =>
          version === "new-gen" ? MenuItemIcons.SANDBOX : "settings",
        menuIndex: "12",
        disabled: true,
        hidden: true,
      },
    ],
    isMenuOpened: true,
  }),
  getters: {
    getActiveIndex: (state) => state.activeIndex,
    getMenuItems: (state) => state.menuItems,
    getIsMenuOpened: (state) => state.isMenuOpened,
  },
  actions: {
    setActiveMenu(menuName: MenuItemNames) {
      const relevantMenu = this.menuItems.find((m) => m.name === menuName);
      this.activeIndex = relevantMenu ? relevantMenu.menuIndex : null;
    },
    clearActiveMenu() {
      this.activeIndex = null;
    },
    setMenuItemVisibility(menuName: MenuItemNames, visible: boolean) {
      const relevantMenu = this.menuItems.find(
        (menuItem) => menuItem.name === menuName,
      );
      if (!relevantMenu) {
        return;
      }
      relevantMenu.hidden = !visible;
    },
    setMenuItemDisabled(menuName: MenuItemNames, disabled: boolean) {
      const relevantMenu = this.menuItems.find(
        (menuItem) => menuItem.name === menuName,
      );
      if (!relevantMenu) {
        return;
      }
      relevantMenu.disabled = disabled;
    },
    setMenuOpen(isOpen: boolean) {
      this.isMenuOpened = isOpen;
    },
  },
});
